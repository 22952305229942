<template>
  <footer class="bg-gray-800 sticky top-[100vh]" aria-labelledby="footer-heading">
    <div class="px-4 sm:px-6 lg:px-8">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl py-12  lg:py-16 ">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="grid grid-cols-1 gap-8 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm md:text-base font-semibold text-gray-100 tracking-wider uppercase">Informatie</h3>
              <ul role="list" class="mt-4 space-y-3">
                <li>
                  <RouterLink :to="{ name: 'Nieuws' }"
                    class="text-base text-gray-300 hover:text-white focus:outline-none rounded focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white focus-visible:border-white">
                    Nieuws </RouterLink>
                </li>

                <li>
                  <RouterLink :to="{
                    name: 'Pagina',
                    params: {
                      id: '4673048',
                      page: 'lokaal-sportakkooord'
                    }
                  }"
                    class="text-base text-gray-300 focus:outline-none hover:text-white rounded focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white focus-visible:border-white">
                    Lokaal sportakkoord </RouterLink>
                </li>
 
                <li>
                  <RouterLink :to="{
                    name: 'Pagina',
                    params: {
                      id: '7192806',
                      page: 'veelgestelde-vragen'
                    }
                  }"
                    class="text-base text-gray-300 focus:outline-none hover:text-white rounded focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white focus-visible:border-white">
                    Veelgestelde vragen </RouterLink>
                </li>



                <li>
                  <RouterLink :to="{
                    name: 'Pagina',
                    params: {
                      id: '5778718',
                      page: 'contact'
                    }
                  }"
                    class="text-base text-gray-300 hover:text-white focus:outline-none rounded focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white focus-visible:border-white">
                    Contact </RouterLink>

                </li>
              </ul>
              
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm md:text-base font-semibold text-gray-100 tracking-wider uppercase">Aanbod</h3>
              <ul role="list" class="mt-4 space-y-3">
                <li>
                  <RouterLink :to="{ name: 'Activiteiten' }"
                    class="text-base text-gray-300 focus:outline-none hover:text-white rounded focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white focus-visible:border-white">
                    Activiteiten  </RouterLink>
                </li>
                <li>
                  <RouterLink :to="{ name: 'Doorverwijzen professionals' }"
                    class="text-base text-gray-300 focus:outline-none hover:text-white rounded focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white focus-visible:border-white">
                    Doorverwijzen </RouterLink>
                </li>
                <li>
                <RouterLink :to="{
                    name: 'Pagina',
                    params: {
                      id: '1841598',
                      page: 'clubondersteuning'
                    }
                  }"
                    class="text-base text-gray-300 focus:outline-none hover:text-white rounded focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white focus-visible:border-white">
                    Clubondersteuning </RouterLink>
                </li>
                <li>
                  <RouterLink :to="{
                    name: 'Pagina',
                    params: {
                      id: '4426708',
                      page: 'workshops-voor-professionals'
                    }
                  }"
                    class="text-base text-gray-300 focus:outline-none hover:text-white rounded focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white focus-visible:border-white">
                    Workshops voor professionals </RouterLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-8 xl:mt-0">
          <h3 class="text-sm md:text-base font-semibold text-gray-100 tracking-wider uppercase">Schrijf je in voor onze
            nieuwsbrief!</h3>
          <div v-if="!newsletter_msg">
            <p class="mt-4 text-base text-gray-300">Blijf op de hoogte en ontvang het laatste nieuws.
            </p>

            <form class="mt-4 sm:flex sm:max-w-md" @submit.prevent="subscribe_newsletter">
              <label for="email-address" class="sr-only">Emailadres</label>
              <input v-model="subscribe_newsletter_email" type="email" name="email-address" id="email-address"
                autocomplete="email" required
                class="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                placeholder="Je mailadres">
                <input v-model="subscribe_newsletter_name" type="text" name="email-name" id="email-name"
                autocomplete="off"
                class="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                placeholder="Je naam">
              <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <input type="hidden" v-model="honeypot" name="honeypot" />
                <button type="submit"
                  class="w-full bg-rose-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-rose-600">Inschrijven</button>
              </div>
            </form>

          </div>

          <p v-else class="mt-4 text-base text-gray-300">
            {{ newsletter_msg }}
          </p>
          <div class="m-auto text-center mt-6 mb-3 flex-shrink-0 justify-center">
            <a href="https://www.heerenveen.nl/" target="_blank"
              title="Open website Gemeente Heerenveen in een nieuw venster"
              class="inline-block hover:opacity-80 rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400">
              <GemeenteHeerenveenLogo class="h-20" />
            </a>
          </div>
        </div>
      </div>
      <div class="mt-8 border-t border-gray-500 pt-8 md:flex md:items-center md:justify-between">

        <div class="flex space-x-6 md:order-2">
          <a v-for="item in navigation.social" :key="item.name" :href="item.href" title="Open link in een nieuw venster"
            target="_blank"
            class="text-gray-400 hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400">
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div>

        <div class="opacity-90 mt-8 text-sm text-gray-300 md:mt-0 md:order-1">
          <p>
            <RouterLink :to="{
              name: 'Pagina',
              params: {
                id: '3412366',
                page: 'toegankelijkheid'
              }
            }"> Toegankelijkheid </RouterLink> |
            <RouterLink :to="{
              name: 'Pagina',
              params: {
                id: '1210351',
                page: 'algemene-voorwaarden'
              }
            }"> Algemene voorwaarden </RouterLink> |
            <RouterLink :to="{
              name: 'Pagina',
              params: {
                id: '9418561',
                page: 'privacybeleid'
              }
            }"> Privacybeleid </RouterLink> |
            <RouterLink :to="{
              name: 'Pagina',
              params: {
                id: '1334307',
                page: 'over-deze-website'
              }
            }"> Over deze website </RouterLink>
          </p>
        </div>

      </div>
      <p class="text-xs text-gray-300 opacity-60">
        &copy; {{ new Date().getFullYear() }} {{ globalVar.organisation }} - <a
          href="https://www.activiteitenplatform.nl/" target="_blank"
          title="Gemeentelijk activiteiten en gezondheidsplatform">Activiteiten Platform</a> - v{{ globalVar.version }}
      </p>

    </div>
  </div>
  </footer>
</template>
  

<script setup>
import { defineComponent, h } from 'vue'
import GemeenteHeerenveenLogo from './GemeenteHeerenveenLogo.vue'
const navigation = {
  social: [
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/samenscorenvoorgezondheid/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/ScorenVoorGezondheidHeerenveen/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },

    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/scoren-voor-gezondheid-heerenveen',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 20' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M 10.003906 0 C 15.523438 0 20.007812 4.476562 20.007812 10.003906 C 20.007812 15.523438 15.53125 20.007812 10.003906 20.007812 C 4.484375 20.007812 0 15.53125 0 10.003906 C 0 4.484375 4.476562 0 10.003906 0 Z M 8.710938 8.015625 L 10.765625 8.015625 L 10.765625 9.066406 L 10.796875 9.066406 C 11.082031 8.550781 11.777344 8.015625 12.824219 8.015625 C 14.988281 8.015625 15.390625 9.367188 15.390625 11.117188 L 15.390625 14.695312 L 13.25 14.695312 L 13.25 11.527344 C 13.25 10.769531 13.230469 9.800781 12.132812 9.800781 C 11.015625 9.800781 10.847656 10.621094 10.847656 11.472656 L 10.847656 14.695312 L 8.707031 14.695312 L 8.707031 8.015625 Z M 7.230469 6.164062 C 7.230469 6.773438 6.734375 7.277344 6.113281 7.277344 C 5.503906 7.277344 5 6.78125 5 6.164062 C 5 5.550781 5.496094 5.046875 6.113281 5.046875 C 6.726562 5.046875 7.230469 5.542969 7.230469 6.164062 Z M 5.003906 8.015625 L 7.230469 8.015625 L 7.230469 14.695312 L 5.003906 14.695312 Z M 5.003906 8.015625 '
            }),
          ]),
      }),
    },

    {
      name: 'Youtube',
      href: 'https://www.youtube.com/channel/UCQkJ009qNgd4LPVoGC1YrvA',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },

  ],
}
</script>


<style>
#email-name {
  right: -99998rem;
  position: fixed;
  visibility: hidden;
}
</style>

<script>
export default {

  data() {
    return {
      subscribe_newsletter_email: '',
      subscribe_newsletter_name: '',
      honeypot: '',
      newsletter_msg: '',
    }
  },
  created() {
    this.honeypot = new Date().toISOString()
  },
  mounted() {
    if (this.userVar.user) { this.subscribe_newsletter_email = this.userVar.user.email }
  },
  methods: {

    subscribe_newsletter() {


      let data = {
        'email': this.subscribe_newsletter_email,
        'name': this.subscribe_newsletter_name,
        'honeypot':this.honeypot,
      }

      //console.log(this.globalVar.urls.newsletter_api)
      //console.log(this.csrfToken.csrf)

      fetch(`${this.globalVar.urls.newsletter_api}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'X-CSRFTOKEN': `${this.csrfToken.csrf}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => Promise.all([response.status, response.json()]))
        .then(([status, data]) => {
          console.log('data:', data);
          console.log('status:', status);

          this.newsletter_msg = data.msg

        })
        .catch((error) => {
          console.error('Error:', error);

          throw error
        })

    }
  }
}
</script>
