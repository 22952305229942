<template>

  <Disclosure as="nav"
  class="py-3 left-0 top-0 right-0 z-20" 
  v-bind:class="{ 'xl:absolute': $route.name == 'RegisterUser' },{ 'xl:absolute': $route.name == 'RegisterSupplier' },{ 'xl:absolute': $route.name == 'Home' }" 
   v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-2  ">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center md:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open hoofdmenu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch md:justify-start">
          <div class="flex flex-shrink-0 items-center">
            <RouterLink title="Logo Samen Scoren voor Gezondheid, Doe je mee?" to="/"
              class="focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-700">
              <Logo class="h-16 -my-4 w-auto"/>
            </RouterLink>
          </div>
          <div class="hidden md:ml-4 md:block">
            <div class=" navbar flex space-x-4">
              <RouterLink v-for="item in globalVar.urls.navbar" :key="item.name" :to="item.href"
              class="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium
              focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-700"
              :aria-current="item.current ? 'page' : undefined"
              active-class="bg-gray-700 text-white"
              > {{ item.name }}</RouterLink>
            </div>
          </div>
        </div>
  
        <div class="absolute inset-y-0 right-4 flex items-center justify-end">
          
          <button v-if=userVar.user type="button" class="hidden rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sku-500 focus:ring-offset-2">
            <span class="sr-only">Bekijk meldingen</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
   
          <Menu as="div" class="relative ml-4 flex-shrink-0" v-if="userVar.user">
            <div>
              <MenuButton class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50" >
                <img v-if=userVar.user.img class="h-8 w-8 rounded-full" :src=userVar.user.img :alt=userVar.user.firstName />
                <UserCircleIcon v-if=!userVar.user.img class="relative h-8 w-8 text-gray-600" aria-hidden="true" />
                <CheckIcon class=" hidden absolute bottom-0 right-0 block h-3 w-3 rounded-full bg-green-500 ring-2 ring-white text-white" aria-hidden="true" />

                <span class="ml-3 hidden text-sm font-medium text-gray-700 lg:block"><span class="sr-only">Open gebruikers menu voor </span> {{userVar.user.firstName}} </span>
                <ChevronDownIcon class="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-600 lg:block" aria-hidden="true" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                
                <MenuItem v-for="item in userVar.urls.userNavigation" :key="item.name" v-slot="{ active }">
                  <a @click="routerLinkMethod(item.href)" :class="[active ? 'bg-gray-100' : '', 'cursor-pointer flex py-2 px-4 text-sm text-gray-700']">
                    <component :is=getNavIcon(item.icon)
                      :class="[active ? 'text-gray-800 group-hover:text-white' : 'text-gray-600 group-hover:text-gray-800', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" >
                    </component>
                    <span class="truncate">{{ item.name }}</span>
                  </a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
  
          <button 
          @click="showModal('register')"
          v-if="!userVar.user" 
          class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 rounded-md  px-4 py-2 bg-white hidden lg:block focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2">
            Registreren 
          </button>
  
          <button
          id="login"
          @click="showModal('login')"
          v-if="!userVar.user"
          class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-rose-600 hover:bg-rose-700 hidden lg:block focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"> 
          Login
          </button>

          <button
            @click="showModal('login')"
            v-if="!userVar.user"
            class="relative ml-2 lg:ml-4 lg:hidden focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sku-500 focus:ring-offset-2 rounded-full"> 

            <XMarkIcon class="absolute bottom-0 right-0 block h-3 w-3 rounded-full bg-orange-500 ring-2 ring-white text-white" aria-hidden="true" />
  
            <UserCircleIcon class="h-8 w-8 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white rounded-full" aria-hidden="true" />
          </button>
     
        </div>
  
      </div>
    </div>

    <DisclosurePanel  v-slot="{ active, close }">
      <div class="space-y-1 px-2 pt-2 pb-3">

        <RouterLink 
          v-for="item in globalVar.urls.navbar"
          :key="item.name"
          @click="close"
          :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-700 bg-gray-200 hover:bg-gray-700 hover:text-white', 'w-full block px-3 py-2  text-center rounded-md text-base font-medium']"
          :to="item.href"
          :aria-current="item.current ? 'page' : undefined">
          {{ item.name }}
        </RouterLink>
      
      </div>
    </DisclosurePanel>

</Disclosure>
  
  
    <Modal :ModalContent="modal_login" v-if="modal_login">
      <template #title>
        Login
      </template>
  
      <template #content>

        <div class="rounded-md bg-yellow-50 p-4 hidden">
          <div class="flex">
            <div class="flex-shrink-0">
              <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <div class="text-sm text-yellow-700">
               <p>Om in te loggen moet je eerst een nieuw account maken. Maak een nieuwe account aan via de onderstaande link.</p>
              </div>
            </div>
          </div>
        </div>

        <p v-if="login_msg.detail" class="mt-2 text-sm text-red-600" id="email-error">{{ login_msg.detail }}</p>
  
        <div v-if="!login_msg.detail">
            <div>
                <label for="email" class="mt-3 block text-sm font-medium text-gray-700">Email</label>
                <div class="relative mt-1 rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 h-10">
                      
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                            <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                        </svg>
                    </div>
                    <input v-model="email" id="email" name="email" type="email" autocomplete="email" required="" class="mt-1 pl-10 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm" />
                    <div v-if="login_msg.email" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 h-10">
                 
                        <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div v-if="login_msg.non_field_errors" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 h-10">
                    
                        <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <p v-if="login_msg.email" class="mt-2 text-sm text-red-600" id="email-error">{{ login_msg.email[0] }}</p>
                    <p v-if="login_msg.non_field_errors" class="mt-2 text-sm text-red-600" id="email-error">{{ login_msg.non_field_errors[0] }}</p>
  
                </div>
            </div>
  
            <div>
                <label for="password" class="mt-3 block text-sm font-medium text-gray-700">Wachtwoord</label>
                <div class="relative mt-1 rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 h-10">
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required="" class="mt-1 pl-10 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm" />
                    
                    <div v-if="login_msg.password" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 h-10">
             
                        <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div v-if="login_msg.non_field_errors" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 h-10">
          
                        <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    
                    <p v-if="login_msg.password" class="mt-2 text-sm text-red-600" id="email-error">{{ login_msg.password[0] }}</p>
                    <p v-if="login_msg.non_field_errors" class="mt-2 text-sm text-red-600" id="email-error">{{ login_msg.non_field_errors[0] }}</p>
  
                </div>
            </div>
  
        </div>
        <div class="flex items-center justify-between mt-3" v-if="!login_msg.detail">
     
  
              <div class="text-sm">

              <RouterLink :to="{ name: 'Wachtwoord vergeten'}" class="font-medium text-sky-600 hover:text-sky-500" @click='modal_login = null'>
                Wachtwoord vergeten?
                </RouterLink>
                
              </div>
          </div>
  
          <div>
              
              <button v-if="!login_msg.detail" :disabled="modal_btn_loading" @click="login" type="button" class="mt-3 flex w-full justify-center rounded-md border border-transparent bg-rose-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2">
      <!--  v-model="modal_btn_loading" -->  
                  <span v-if="!modal_btn_loading">Login</span>    
  
                  <div v-if="modal_btn_loading" class="pointer-events-none  flex items-center  ">
  
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        
                  </div>
  
              </button>
          </div>
      </template>
      
      <template #footer>
        <div class="mt-6">
          <div class="relative">
              <div class="absolute inset-0 flex items-center">
                  <div class="w-full border-t border-gray-300" ></div>
              </div>
              <div class="relative flex justify-center text-sm">
                  <span class="bg-white px-2 text-gray-500">Of registreren als</span>
              </div>
          </div>
  
          <div class="mt-6 grid grid-cols-3 gap-3">
  
                <router-link :to="{ name: 'RegisterUser'}"  @click='modal_login = null'
                  title="Registreren als deelnemer" type="button"
                  class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                  Deelnemer
                </router-link>
  
                <router-link :to="{ name: 'RegisterSupplier'}"  @click='modal_login = null' 
                  title="Registreren als aanbieder van acitiviteiten" type="button"
                  class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                  Aanbieder
                </router-link>
  
                <router-link :to="{ name: 'RegisterProfessional'}"  @click='modal_login = null' 
                  title="Registreren als doorverwijzer" type="button"
                  class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                  Doorverwijzer
                </router-link>
       
          </div>
  
        </div>
      </template>
    </Modal>


    <Modal :ModalContent="modal_register" v-if="modal_register">
      <template #title>
        Registreren
      </template>
  
      <template #content>

        <div class="mt-2">
          <p class="mt-3 text-base  sm:mt-5 sm:text-xl lg:text-lg  px-6 text-center ">Krijg toegang tot het grootste activiteiten platform van de gemeente Heerenveen! Doe je mee?</p>
          <p class="mt-4 text-base text-gray-800 sm:mt-5 sm:text-xl lg:text-lg  px-6 text-center  font-semibold ">Registreren als:</p>
          <div class="mt-2 grid grid-cols-3 gap-3">
            
            <router-link :to="{ name: 'RegisterUser'}" @click='modal_register = null'
              title="Registreren als deelnemer" type="button"
              class="inline-flex w-full justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
              Deelnemer
            </router-link>

            <router-link :to="{ name: 'RegisterSupplier'}" @click='modal_register = null'
              title="Registreren als aanbieder van acitiviteiten" type="button"
              class="inline-flex w-full justify-center rounded-md rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
              Aanbieder
            </router-link>

            <router-link
              :to="{ name: 'RegisterProfessional'}"
              @click='modal_register = null'
              title="Registreren als doorverwijzer van arragementen" 
              type="button"
              class="inline-flex w-full justify-center rounded-md rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
              Doorverwijzer
            </router-link>
          </div>
  
        </div>
      </template>
      <template #footer>        <div class="mt-6">
          <div class="relative">
              <div class="absolute inset-0 flex items-center">
                  <div class="w-full border-t border-gray-300" ></div>
              </div>
              <div class="relative flex justify-center text-sm">
                  <span class="bg-white px-2 text-gray-500">Heb je al een account?</span>
              </div>
          </div>
  
          <div class="mt-6 grid grid-cols-1 gap-3">
  
                <button
                  @click="modal_register = null, showModal('login')"
                  title="Registreren als deelnemer" type="button"
                  class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                  Login
          </button>
  
 
  
  
          </div>
  
        </div></template>
    </Modal>

    
  </template>
  
  <script setup>
  import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
  import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import { Bars3Icon,
    UserCircleIcon,
    ChevronDownIcon,
    BellIcon,
    XMarkIcon,
    UsersIcon,
    UserIcon,
    FlagIcon,
    UserGroupIcon,
    CalendarIcon,
    KeyIcon,
    CogIcon,
    ChartPieIcon,
    NewspaperIcon,
    ClipboardIcon,
    BriefcaseIcon,
    InboxIcon,

    ArrowRightOnRectangleIcon,
    HomeIcon,
    TrashIcon,
    PhotoIcon,
    
    CheckBadgeIcon,
    } from '@heroicons/vue/24/outline'
  
    import Modal from './LoginModal.vue'
    import Logo from './Logo.vue'
  
  </script>
  
  <style>
    .navbar .router-link-exact-active { color:white !important}
  </style>
  
  <script>

  export default {
    data() {
        return {
            modal_login: [],
            modal_register: [],
            modal_btn_loading: false,
            login_valid: true,
            login_msg: "",
            email: "",
            username: "",
            username_validation_msg: "",
            username_validation_status: "",
            password: "",
            password_validation_msg: "",
            password_validation_status: "",
            btn_confirmed: "hidden",
            UsersIcon: UsersIcon,
            UserIcon: UserIcon,
            FlagIcon: FlagIcon,
            UserGroupIcon:UserGroupIcon,
            CalendarIcon: CalendarIcon,
            KeyIcon: KeyIcon,
            CogIcon: CogIcon,
            ChartPieIcon: ChartPieIcon,
            NewspaperIcon: NewspaperIcon,
            ClipboardIcon:ClipboardIcon,
            BriefcaseIcon:BriefcaseIcon,
            InboxIcon:InboxIcon,

            ArrowRightOnRectangleIcon: ArrowRightOnRectangleIcon,
            PhotoIcon: PhotoIcon,
        };
    },
    watch: {},
    methods: {

      routerLinkMethod(href) {
          this.$router.push(href); 
        },
        getNavIcon(icon) {
            if (this.$data.hasOwnProperty(icon)) {
                return this.$data[icon];
            }
        },
        showModal(modus) {
            if (modus == "login") {
                this.modal_login = new Array("true", "title", "message", "status");
            }
            else if (modus == "register") {
                this.modal_register = new Array("true", "title", "message", "status");
            }
        },
        closeModal() {
            this.modal_login = new Array();
            this.modal_register = new Array();
        },
        login() {
            this.modal_btn_loading = true;
            console.log(this.csrfToken.csrf);
            //   console.log({{ csrf_token }})
            let login_data = {
                "email": this.email,
                "password": this.password,
            };
            fetch(`${this.globalVar.urls.login_api}`, {
                method: "POST",
                body: JSON.stringify(login_data),
                headers: {
                    "X-CSRFTOKEN": `${this.csrfToken.csrf}`,
                    "Content-Type": "application/json",
                },
            })
                .then(response => Promise.all([response.status, response.json()]))
                .then(([status, data]) => {
                //console.log('data:', data);
                //console.log('status:', status);
                this.modal_btn_loading = false;
                this.login_msg = data;
                if (status == 202) {
                    location.reload();
                }
            })
                .catch((error) => {
                console.error("Error:", error);
                this.modal_btn_loading = false;
                throw error;
            });
        }, // login
    },
    components: { CheckBadgeIcon, CheckIcon }
}
  </script>
  
