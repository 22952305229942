import { createRouter, createWebHistory } from 'vue-router'
import { seoGuardWithNext } from '@aminoeditor/vue-router-seo';

const title = 'Samen Scoren voor Gezondheid';
const router = createRouter({

  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      //component: HomeView
      component: () => import('../views/HomeView.vue'),
      meta: {
        seo: {
          title: `Home | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Gezond zijn, je gezond voelen en zin in het leven hebben is van groot belang. Voor jezelf en voor onze samenleving.'
            },
            {
              property: 'og:title',
              content: `Home | ${title}`,
            },
            {
              property: 'og:description',
              content: 'Gezond zijn, je gezond voelen en zin in het leven hebben is van groot belang. Voor jezelf en voor onze samenleving.'
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/'
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
            
          ],
        }
      },
    },

    {
      path: '/activiteiten/',
      name: 'Activiteiten',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/EventsView.vue'),
      meta: {
        seo: {
          title: `Activiteiten | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:title',
              content: `Activiteiten | ${title}`,
            },
            {
              property: 'og:description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/activiteiten/'
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },

    },
    {
      path: '/activiteiten/:id/:activity/', 
      name: 'Activiteit',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/EventView.vue'),
      meta: {
        seo: {
          title:  `| ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:title',
              content: `Activiteiten | ${title}`,
            },
            {
              property: 'og:description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/activiteiten/'
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },
    },
    {
      path: '/activiteiten/tag/:tag/', 
      name: 'Activiteit tag',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/TagPageView.vue'),
      meta: {
        seo: {
          title:  `Tag | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:title',
              content: `Tag | ${title}`,
            },
            {
              property: 'og:description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/tag/', 
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },
    },
    {
      path: '/activiteiten/categorie/:cat/', 
      name: 'Activiteit categorie',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/CatPageView.vue'),
      meta: {
        seo: {
          title:  `Categorie | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:title',
              content: `Categorie | ${title}`,
            },
            {
              property: 'og:description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/categorie/', 
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },
    },
    {
      path: '/aanbieders/',
      name: 'Aanbieders',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/SuppliersView.vue'),
      meta: {
        seo: {
          title: `Aanbieders | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Aanbieders van Sport, gezondheid en culturele activiteiten in de Gemeente Heerenveen'
            },
            {
              property: 'og:title',
              content: `Aanbieders | ${title}`,
            },
            {
              property: 'og:description',
              content: 'Aanbieders van Sport, gezondheids en culturele activiteiten in de Gemeente Heerenveen'
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/aanbieders/', 
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },
    },
    {
      path: '/aanbieders/:id/:supplier/', 
      name: 'Aanbieder',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/SupplierView.vue'),
      meta: {
        seo: {
          title:  `| ${title}`,
          metaTags: [
            {
              name: 'description',
              content: `Informatie over aanbieder: ${title} `,
            },
            {
              property: 'og:title',
              content: `| ${title}`,
            },
            {
              property: 'og:description',
              content: `Informatie over aanbieder: ${title} `,
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/aanbieders/', 
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },
    },
    {
      path: '/nieuws/',
      name: 'Nieuws',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/NewsView.vue'),
      meta: {
        seo: {
          title: `Nieuws | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Nieuws uit Heerenveen, Samen Scoren voor Gezondheid'
            },

            {
              property: 'og:title',
              content:  `Nieuws | ${title}`,
            },
            {
              property: 'og:description',
              content: `Informatie over aanbieder: ${title} `,
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/nieuws/', 
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },
    },
    {
      path: '/nieuws/:id/:news/', 
      name: 'Nieuws item',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/NewsItemView.vue'),
      meta: {
        seo: {
          title:  `| ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Nieuws en activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:title',
              content:  `Nieuws | ${title}`,
            },
            {
              property: 'og:description',
              content: 'Nieuws en activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/nieuws/'
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },
    },
    {
      path: '/nieuws/archief/', 
      name: 'Alle nieuwsberichten',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/NewsArchiveView.vue'),
      meta: {
        seo: {
          title:  `Alle nieuwsberichten | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Bekijk alle nieuwsberichten'
            },
          ],
        }
      },
    },
    {
      path: '/pagina/:id/:page/', 
      name: 'Pagina',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/PageView.vue'),
      meta: {
        seo: {
          title:  `| ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:title',
              content:  `Nieuws | ${title}`,
            },
            {
              property: 'og:description',
              content: 'Activiteiten in Heerenveen: Aanbod sport, cultuur, ontmoeting en gezondheid in de gemeente Heerenveen'
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/pagina/', 
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },
    },



    {
      path: '/account/:tab/',
      name: 'Account',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AccountView.vue'),
      // meta: { authorize: [Role.Admin] }
      meta: {
        seo: {
          title: `Account | ${title}`,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            },
          ],
        }
      },
    },

    {
      path: '/doorverwijzen-naar-professionals/',
      name: 'Doorverwijzen professionals',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/arrangementsView.vue'),
      meta: {
        seo: {
          title: `Doorverwijzen professionals | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Doorverwijzen naar professionals in de Gemeente Heerenveen'
            },
          ],
        }
      },

    },

    {
      path: '/doorverwijzen-naar-professionals/:id/:arrangement/', 
      name: 'Doorverwijzen professionals item',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/arrangementView.vue'),
      meta: {
        seo: {
          title:  `| ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Doorverwijzen naar professionals in de Gemeente Heerenveen'
            },
            {
              property: 'og:title',
              content:   `| ${title}`,
            },
            {
              property: 'og:description',
              content:  'Doorverwijzen naar professionals in de Gemeente Heerenveen'
            },
            {
              property: 'og:url',
              content: 'https://www.samenscorenvoorgezondheid.nl/doorverwijzen-naar-professionals/', 
            },
            {
              property: 'og:image',
              content: 'https://www.samenscorenvoorgezondheid.nl/assets/logo.png'
            },
            {
              property: 'og:type',
              content: 'website'
            },
            {
              property: 'og:locale',
              content: 'nl_NL'
            },
            {
              property: 'og:site_name',
              content: 'Samen Scoren voor Gezondheid'
            },
          ],
        }
      },
    },


    {
      path: '/wachtwoord-vergeten/',
      name: 'Wachtwoord vergeten',
      component: () => import('../views/ForgotPassword.vue'),
      meta: {
        seo: {
          title: `Wachtwoord vergeten? | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Wachtwoord vergeten?'
            },
          ],
        }
      },
    },
    {
      path: '/registreren-als-deelnemer/',
      name: 'RegisterUser',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/RegisterUserView.vue'),
      // meta: { authorize: [Role.Admin] }
      meta: {
        seo: {
          title: `Als gebruiker registreren | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Registreer je als gebruiker op ons platform Samen Scoren voor Gezondheid Heerenveen'
            },
          ],
        }
      },
    },
    {
      path: '/registreren-als-aanbieder/',
      name: 'RegisterSupplier',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/RegisterSupplierView.vue'),
     // meta: { authorize: [Role.Admin] }
      meta: {
        seo: {
          title: `Als aanbieder registreren | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Registreer je als aanbieder op ons platform Samen Scoren voor Gezondheid Heerenveen'
            },
          ],
        }
      },
    },
    {
      path: '/registreren-als-doorverwijzer/',
      name: 'RegisterProfessional',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/RegisterReferrerView.vue'),
     // meta: { authorize: [Role.Admin] }
      meta: {
        seo: {
          title: `Als doorverwijzer registreren | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Registreer je als doorverwijzer op ons platform Samen Scoren voor Gezondheid Heerenveen'
            },
          ],
        }
      },
    },

    {
      path: '/bedankt-voor-je-registratie/',
      name: 'thank-you-for-registering',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/ThankYouForRegisteringView.vue'),
      // meta: { authorize: [Role.Admin] }
      meta: {
        seo: {
          title: `Bedankt voor je registratie | ${title}`,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            },
          ],
        }
      },
    },

    {
      path: '/bedankt-voor-je-doorverwijzing/',
      name: 'thank-you-for-referring',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/ThankYouForReferringView.vue'),
      // meta: { authorize: [Role.Admin] }
      meta: {
        seo: {
          title: `Bedankt voor je doorverwijzing | ${title}`,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            },
          ],
        }
      },
    },

    {
      path: '/clubondersteuning/',
      name: 'Clubondersteuning',
      redirect: '/pagina/1841598/clubondersteuning/',
    },
    {
      path: '/sportakkoord/',
      name: 'Lokaal Sportakkoord',
      redirect: '/pagina/4673048/lokaal-sportakkoord/',
    },
    {
      path: '/algemenevoorwaarden/',
      name: 'Algemene voorwaarden',
      redirect: '/pagina/1210351/algemene-voorwaarden/',
    },
    {
      path: '/veelgestelde-vragen/',
      name: 'Veelgestelde vragen',
      redirect: '/pagina/7192806/veelgestelde-vragen/',
    },
    {
      path: '/contact/',
      name: 'Contact',
      redirect: '/pagina/5778718/contact/',
    },
    {
      path: '/privacybeleid/',
      name: 'Privacybeleid',
      redirect: '/pagina/9418561/privacybeleid/',
    },
    {
      path: '/trainingen/',
      name: 'Trainingen',
      redirect: '/pagina/6755835/trainingen/',
    },
    {
      path: '/over-deze-website/',
      name: 'Over deze website',
      redirect: '/pagina/1334307/over-deze-website/',
    },

    {

      path: '/nationaalkampioen/',
      name: 'Nationale kampioenen',
      redirect: '/pagina/9272679/nationale-kampioenen/',
    },

    {
      path: '/:pathMatch(.*)*',
      name: 'Pagina niet gevonden',
      component: () => import('../views/404.vue'),
      meta: {
        seo: {
          title: `Pagina niet gevonden | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: 'Verbeter je fitheid, voeding en mentale gezondheid met ons traningsaanbod. We helpen je bij het behalen van je doelen. Schrijf je nu in voor een gezonde levensstijl.'
            },
          ],
        }
      },
    },

  ],
  scrollBehavior(to, from, savedPosition) {
      return { top: 0 }
  },
})


router.beforeEach(seoGuardWithNext)

export default router


/*
import { authenticationService } from "../_services/authentication.service";
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  const currentUser = authenticationService.currentUserValue;
  
  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ path: "/login", query: { returnUrl: to.path } });
    }
  
    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next({ path: "/" });
    }
  }
  next();
  */